import React, { useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, Text, Tooltip, Box } from '@chakra-ui/react';
import { AiOutlineHistory } from 'react-icons/ai';
import { NavGroup } from '~/components/Layout/NavGroup';
import { NavItem } from '~/components/Layout/NavItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { CiBookmark } from 'react-icons/ci';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { useViewer } from '~/hooks';

type Props = {
    storedHistory: string;
    isLoading: boolean;
    onLoadingChange: (arg: boolean) => void;
};

export const History = ({ storedHistory = '[]', isLoading, onLoadingChange }: Props) => {
    const location = useLocation();
    const history = JSON.parse(storedHistory) || [];
    const latest = history?.[0];
    const navigate = useNavigate();
    const convertTitle = (title: string) => title?.split('-').slice(2).join('-');

    React.useEffect(() => {
        const regex = /\/projekte\/\d+\/\d+/;
        const isSubproject = regex.test(location.pathname);
        onLoadingChange(isSubproject ? !location.pathname.startsWith(latest?.key) : false);
    }, [latest?.key, location.pathname]);

    if (history.length === 0) {
        return null;
    }

    return (
        <NavGroup label="Projekthistorie">
            {latest && (
                <NavItem
                    key={latest?.key}
                    to={`${latest?.key}/details`}
                    label={convertTitle(latest?.title)}
                    icon={CiBookmark}
                    isLoading={isLoading}
                />
            )}
            {history?.length > 1 && (
                <Menu closeOnSelect={false}>
                    {({ isOpen }) => (
                        <>
                            <MenuButton
                                as={Button}
                                variant="ghost"
                                textAlign="left"
                                color="gray.700"
                                py={2}
                                px={3}
                                fontSize="sm"
                                rightIcon={isOpen ? <FiChevronUp /> : <FiChevronDown />}
                                leftIcon={<AiOutlineHistory />}
                                _hover={{ bg: 'ucpw-brand', color: 'white' }}
                                _active={{
                                    color: 'ucpw-brand',
                                    '&:hover': { bg: 'ucpw-brand', color: 'white' },
                                }}
                            >
                                Projektverlauf
                            </MenuButton>
                            {/** wrapping MenuList with Box fixes Popper warning: CSS "margin" styles cannot be used */}
                            <Box>
                                <MenuList>
                                    {history.map(
                                        (
                                            {
                                                key,
                                                formattedDate,
                                                title = '',
                                                address = '',
                                                ...props
                                            }: any,
                                            idx: number
                                        ) =>
                                            idx === 0 ? null : (
                                                <Tooltip
                                                    key={key}
                                                    label={
                                                        <Text>
                                                            {formattedDate}
                                                            {address && (
                                                                <>
                                                                    <br />
                                                                    {address}
                                                                </>
                                                            )}
                                                        </Text>
                                                    }
                                                    hasArrow
                                                    placement="right"
                                                    openDelay={200}
                                                    bg="ucpw-brand"
                                                >
                                                    <MenuItem
                                                        onClick={() => navigate(`${key}/details`)}
                                                    >
                                                        {convertTitle(title)}
                                                    </MenuItem>
                                                </Tooltip>
                                            )
                                    )}
                                </MenuList>
                            </Box>
                        </>
                    )}
                </Menu>
            )}
        </NavGroup>
    );
};

export const Projecthistory = () => {
    const [isLoading, setIsLoding] = useState<boolean>(false);
    const { employee } = useViewer();
    const location = useLocation();

    return (
        <History
            key={location.key}
            storedHistory={employee?.projekthistorie}
            isLoading={isLoading}
            onLoadingChange={setIsLoding}
        />
    );
};
