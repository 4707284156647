import { assign, createMachine, actions } from 'xstate';
import { Aufgabe } from '~/gql/ucpw/graphql';

export type TaskPermissionsMachineContext = {
    task?: Aufgabe | Record<string, any>;
    viewer?: any;
    [key: string]: any;
};

export const createTaskPermissionsMachine = () =>
    createMachine(
        {
            /** @xstate-layout N4IgpgJg5mDOIC5QBcCGsDWAFMAnAtgJayyED2AdrAHSEQA2YAxANoAMAuoqAA5mnJyFbiAAeiALQAmAGwBGagHYALDJkBWABwy26ufqkAaEAE9JexdRkBOawGYZmtrOtTNygL4fjaTDgLEpJQ0dIysclxIIHwCQiLiCBKK1srUbsqy6jbq1o6axmaJ6lJ21GzKis7KctY1UtbqXj7o2HhEJEI0AK48EKjIzOyRvPyEgpTx5mzUyurJuTJ2dnO6dgWSGVJpWsrKyypScnoyTSC+rQEdwdQ9fQPhw9Gj48JRCepsCnJqjtYqymxNFo1qYNlItlIdns5hkjt9Tud-O0glQbr1+mAAML0fiQAAqLVYnBEMTGcTekhUVnUyk0ikUdNqmmZinWiVkqU0dlUsnp4P06ka3jOLSRgU6aLuWJxsHxhJYERJz3JoASh2s1GKDLmij0mjkiikyjZ0hknO5Ml5hsORyFzT8bXF11uGIA8jwwBQCZgiY9SS9JghVJY5MtdoolrS3DITRzqFyeTI+TbBQjRY6rqiXQN3Z7vRgHkrYhMKQhkupqPYcnYq3JMqzQezBWVbJpIclnCpmWmHZcUTQAMaoCgAVXR9yGRbJJdViGUtmodk+bB0lQsbAbhSSaVybn1847djcPYuyIlQ9H48Giqi-pVYjnNhbUccWTkbBrbLN1AN7+sQLsTR-xpKQTzFTNB2HMcpVYKQ-WVGcHyDZIf0WGlFDNBx5xBQo6wrLJin0b4MmcOYwIzftqAvaCMVYOx4OLV5ZzLBpK2Wexa3rNlQw1OZ-zNawVykCN6XIvtz1wMAMSogALMABwwTFJIxcCUV9KcA1LCRtGmIFdA5Bk3DsOQTSXVJnHBbkDWqWk7EUMSz2uAdlIGWT5MUlywFUoRC1vBCmKQ7Tlh-LVmRKVwMM3SQ6wUNs9mZf82zs9wvGFCgyAgOARERCjOg0+8EgkOspGmKo-h0JctFyE1dS2Wk5HcPR6hyQ4HKdVFQjAfLEMK4TSnfI81EE4y2FqE01E5Fdkl1DRajNNqIMlDFuoC94lHkNgNycdwkxqfJGwkdQ7JmQ1ExSCMsgWyjs2lXEIHzFbA0OzQ0iTGtbD+Bo9hjA6lksedvkUd8mrNO0RV7RysyvXMvRaR7Sw+WLihXeQdCyEpjV+46AbUSpptyOQrvPKCr3h5ims1H5-2WHS63GurlhsCpFjbHiiackmpVdAB3WHMDJpDgbKAFBTbYSjrcKLEkNJRbABOt5zexx2dRairwAQQ6KAKDlfm-MYp7iqsMKjzswEVDGxsMlQ8p6QyGnu2FHLxI5y8pQAIXk1AullV0ADMADEugoAdNJGA2tL-Kx-3BCN-2+JK2UhCsk20L7RsNDOVcgigADkyGQGiBgFhJki+WtBPcI1aXG0pHEhZkWVqWQTid9MXdVzyS+ig0tjKjCP10ICfq3XYFFcPTZiNYztHstuIfawdPLchSlKkgZvMQu8ep7o0yk2jcRscDQcMkJdLC22z-nfUbs6o5eLzX5b9enVbzFyffRr+ZwNDcEyDvqJYeologbMg-O9O+zl15gCosOfOyAn7FxfmHQKBhzJGnKoPKqI9oqyB-OLOsNYayWkWJA4cAAlMAZAPR8wwN3RI+gXq6CZkDEoQI2wmgainSEK5yhZEWLsVu9pTyL1gXnAulDqF5jhsggqiADQyGFsJD83x+LMkxluLhVgeE6FmGobkqhUoeCAA */
            id: 'taskPermissions',
            predictableActionArguments: true,
            context: {
                task: undefined,
                viewer: undefined,
                canCreate: false,
            },
            tsTypes: {} as import('./taskPermissionsMachine.typegen').Typegen0,
            schema: {
                context: {} as TaskPermissionsMachineContext,
            },
            initial: 'idle',
            states: {
                idle: {
                    entry: ['getPermissions'],
                    always: [
                        {
                            cond: 'hasTask',
                            target: 'update',
                        },
                        {
                            target: 'create',
                        },
                    ],
                },
                update: {
                    always: [
                        { target: 'updateClosedTask', cond: 'isClosedTask' },
                        { target: 'updateOpenTask' },
                    ],
                },
                updateClosedTask: {
                    always: [
                        { target: 'canReopenTask', cond: 'hasReopenPermssion' },
                        {
                            target: 'canNotReopenTask',
                        },
                    ],
                },
                updateOpenTask: {
                    always: [
                        {
                            target: 'canUpdate',
                            cond: 'hasUpdatePermission',
                        },
                        {
                            target: 'canNotUpdate',
                        },
                    ],
                },
                canUpdate: {
                    always: [
                        {
                            target: 'canUpdateOwnTask',
                            cond: 'isOwnTask',
                        },
                        {
                            target: 'canUpdateAssignedTask',
                            cond: 'isAssignedTask',
                        },
                        {
                            target: 'canUpdateBecauseOfFunction',
                            cond: 'hasFunction',
                        },
                        {
                            target: 'canCloseTask',
                            cond: 'canCloseTask',
                        },
                        {
                            target: 'canNotUpdate',
                        },
                    ],
                },
                canUpdateOwnTask: {
                    entry: 'setCanUpdate',
                    type: 'final',
                },
                canUpdateAssignedTask: {
                    entry: 'setCanUpdateErledigt',
                    type: 'final',
                },
                canUpdateBecauseOfFunction: {
                    entry: 'setCanUpdateErledigt',
                    type: 'final',
                },
                canCloseTask: {
                    entry: 'setCanUpdateErledigt',
                    type: 'final',
                },
                canNotUpdate: {
                    entry: 'setCanNotUpdate',
                    type: 'final',
                },
                create: {
                    entry: 'isCreate',
                    initial: 'checkCreatePermission',
                    states: {
                        checkCreatePermission: {
                            always: [
                                {
                                    target: 'canCreate',
                                    cond: 'hasCreatePermission',
                                },
                                {
                                    target: 'canNotCreate',
                                },
                            ],
                        },
                        canCreate: {
                            entry: 'setCanCreate',
                            type: 'final',
                        },
                        canNotCreate: {
                            entry: 'setCanNotCreate',
                            type: 'final',
                        },
                    },
                },
                canReopenTask: {
                    entry: 'setCanUpdateErledigt',
                    type: 'final',
                },
                canNotReopenTask: {
                    entry: 'setCanNotUpdate',
                    type: 'final',
                },
            },
        },
        {
            guards: {
                canCloseTask: (context) => context?.permissions?.additional?.closeTask,
                hasFunction: (context) => {
                    const { task, viewer } = context;
                    const taskFunction = task?.funktion?.bezeichnung;
                    return viewer?.employee?.mitarbeiterFunktion?.some(
                        (fn: any) => fn?.funktion?.bezeichnung === taskFunction
                    );
                },
                isAssignedTask: (context) =>
                    (context?.task?.mitarbeiterIdSachbearbeiter ===
                        context?.viewer?.employee?.id) as boolean,
                isOwnTask: (context) =>
                    context?.task?.mitarbeiterIdUrheber === context?.viewer?.employee?.id,
                hasTask: (context) => Boolean(context.task?.id),
                hasCreatePermission: (context) => {
                    return context?.permissions?.project?.tasks?.canCreate as boolean;
                },
                hasUpdatePermission: (context) =>
                    context?.permissions?.project?.tasks?.canEdit as boolean,
                isClosedTask: (context) => Boolean(context.task?.erledigt),
                hasReopenPermssion: (context) => context?.permissions?.additional?.reopenTask,
            },
            actions: {
                setCanNotCreate: assign({ canCreate: false }),
                setCanCreate: assign({
                    canCreate: true,
                    modalTitle: 'Neue Aufgabe',
                    cancelButtonName: 'Abbrechen',
                    viewerIsCreator: true,
                    pointerEvents: 'auto',
                    cursor: 'auto',
                }),
                setCanNotUpdate: assign({
                    canUpdate: false,
                    modalTitle: 'Aufgabe ansehen',
                    menuItemTitle: 'Ansehen',
                    cancelButtonName: 'Schließen',
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                }),
                setCanUpdate: assign({
                    canUpdate: true,
                    modalTitle: 'Aufgabe bearbeiten',
                    menuItemTitle: 'Bearbeiten',
                    cancelButtonName: 'Abbrechen',
                    pointerEvents: 'auto',
                    cursor: 'auto',
                }),
                setCanUpdateErledigt: assign({
                    canUpdate: { erledigt: true },
                    modalTitle: 'Aufgabe bearbeiten',
                    menuItemTitle: 'Bearbeiten',
                    cancelButtonName: 'Abbrechen',
                    pointerEvents: 'auto',
                    cursor: 'auto',
                }),
                isCreate: assign({ isCreate: true, isUpdate: false }),
                getPermissions: actions.assign((context) => {
                    const permissions =
                        context?.viewer?.permissions?.filter((p: any) =>
                            context?.permissionIds?.includes(p?.id)
                        ) || [];
                    return {
                        viewerIsCreator:
                            context?.viewer?.employee?.id === context?.task?.mitarbeiterIdUrheber,
                        permissions: {
                            ...permissions?.reduce((acc: any, item: any) => {
                                const [a, b] = item?.id?.split('.');
                                const isFlag = a === 'additional';
                                const has = (p: string) => item?.permissions?.includes(p);
                                return {
                                    ...acc,
                                    [a]: {
                                        ...(acc?.[a] || {}),
                                        [b]: isFlag
                                            ? has('enabled')
                                            : {
                                                  canCreate: has('create'),
                                                  canView: has('view'),
                                                  canEdit: has('update'),
                                                  canDelete: has('delete'),
                                              },
                                    },
                                };
                            }, {}),
                        },
                    };
                }),
            },
        }
    );
