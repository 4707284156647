import React, { useRef } from 'react';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useViewer } from '~/hooks';
import { useMutation } from '@apollo/client';
import { GetEmployeeDocument, Mitarbeiter, UpdateEmployeeDocument } from '~/gql/ucpw/graphql';

type StoredSubproject = {
    key: string;
    pathname: string;
    date: Date;
    title: string;
    formattedDate: string;
    address: string;
};

const useUpdateHistory = () => {
    const viewer = useViewer();
    const employee = viewer?.employee as Mitarbeiter;
    const context = { clientName: 'ucpw' };
    const [updateEmployee] = useMutation(UpdateEmployeeDocument, {
        context,
        errorPolicy: 'all',
        onError: (error) => {
            console.error('Update Employee GraphQl Error:', error.graphQLErrors);
            Sentry.captureMessage('Update Employee Project History: GraphQl Error', {
                extra: { error },
            });
        },
        refetchQueries: [
            {
                query: GetEmployeeDocument,
                variables: { id: employee?.id },
                context,
            },
        ],
    });

    const updateHistory = React.useCallback(
        async (projekthistorie = '[]') => {
            if (employee) {
                await updateEmployee({
                    variables: {
                        id: employee?.id,
                        data: {
                            rolleId: employee?.rolleId,
                            niederlassungId: employee?.niederlassungId,
                            name: employee?.name,
                            vorname: employee?.vorname,
                            telefon: employee?.telefon,
                            email: employee?.email,
                            urlaubstage: employee?.urlaubstage,
                            urlaubsgruppe: employee?.urlaubsgruppe,
                            farbe: employee?.farbe,
                            kennung: employee?.kennung,
                            passwort: employee?.passwort,
                            accessIp: employee?.accessIp,
                            projekthistorie,
                        },
                    },
                });
            }
        },
        [employee]
    );

    return { employee, updateHistory };
};

const sortByDate = (a: StoredSubproject, b: StoredSubproject) =>
    b?.date?.getTime?.() - a?.date?.getTime?.();

export function useBrowseHistory(title: string = '', subproject?: any) {
    const { updateHistory, employee } = useUpdateHistory();
    const { pathname = '', key: locationKey = '' } = useLocation();
    const keyRef = useRef('');

    React.useEffect(() => {
        const regex = /\/projekte\/\d+\/\d+/;
        const isSubproject = regex.test(pathname);
        if (isSubproject && !(locationKey === keyRef.current)) {
            const [key] = pathname?.match(regex) || [];
            const [project] = pathname?.match(/\/projekte\/\d+/) || [];

            if (title && subproject && employee) {
                const getStorage = JSON.parse(employee?.projekthistorie || '[]');
                const { plz, strasse, ort } = subproject?.projekt || {};
                const date = new Date();
                const data = {
                    key,
                    project,
                    pathname,
                    date,
                    title,
                    formattedDate: format(date, 'dd.MM.yyyy HH:mm:ss'),
                    address: `${strasse}, ${plz} ${ort}`,
                };

                // updateHistory(JSON.stringify([])); // NOTE: for resetting "projekthistorie"

                if (getStorage.length === 0) {
                    updateHistory(JSON.stringify([data]));
                } else if (getStorage.length > 0 && getStorage[0].key !== key) {
                    const newData = [
                        data,
                        ...getStorage
                            .filter((item: any) => item.project !== project)
                            .sort(sortByDate)
                            .slice(0, 10),
                    ];

                    updateHistory(JSON.stringify(newData));
                }

                keyRef.current = locationKey;
            }
        }
    }, [pathname, title, subproject, locationKey, keyRef.current, employee, updateHistory]);
}
