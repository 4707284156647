import { pick } from 'lodash';
import { interpret } from 'xstate';
import {
    createTaskPermissionsMachine,
    TaskPermissionsMachineContext,
} from './taskPermissionsMachine';
import { useViewer } from '~/hooks';
import log from '~/log';

type Props = TaskPermissionsMachineContext['task'];

export const useTaskPermissions = (task: Props = {}) => {
    log.trace('useTaskPermissions(task)', task);
    const viewer = useViewer();
    const permissionIds = [
        'project.tasks',
        'additional.closeTask',
        'additional.reopenTask',
        'additional.editAllTasks',
    ];

    const getTaskPermission = (task: Props = {}): any => {
        let res = {};
        const service = interpret(
            createTaskPermissionsMachine().withContext({
                task,
                viewer,
                // viewer: getDemoViewer(viewer),
                permissionIds,
            })
        ).onTransition((state) => {
            res = pick(state, 'value', 'context');
        });
        service.start();

        return res;
    };

    const { context } = getTaskPermission(task);

    return { context, getTaskPermission };
};

// only for permission testing
export function getDemoViewer(viewer: Record<string, any> = {}) {
    return {
        ...viewer,
        employee: {
            ...viewer?.employee,
            id: 6267481,
            mitarbeiterFunktionen: [
                {
                    funktion: {
                        bezeichnung: 'Thermographie',
                    },
                },
                {
                    funktion: {
                        bezeichnung: 'Niederlassungsleiter',
                    },
                },
                {
                    funktion: {
                        id: 3,
                        bezeichnung: 'I-Nadis',
                    },
                },
            ],
        },
        // employee: { ...viewer?.employee, id: 57, mitarbeiterFunktionen },
        permissions: [
            {
                id: 'project.tasks',
                permissions: ['view', 'create', 'update', 'delete', 'all'],
            },
            {
                id: 'additional.closeTask',
                permissions: ['enabled'],
                // permissions: [],
            },
            {
                id: 'additional.reopenTask',
                permissions: ['enabled'],
                // permissions: [],
            },
        ],
    };
}
